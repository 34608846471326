:root {
	/* Main text color */
	--body-color: #000;

	/* Secondary text color, dimmed. Make sure to keep contrast WCAG 2.0 AA compliant on var(--window-bg-color) */
	--body-color-muted: #5f5f5f;

	/* Background color of the whole page */
	--body-bg-color: #ccc5c1;

	/* Background color of the main window */
	--window-bg-color: #e4dcd7;

	/* Color of the date marker, text and separator */
	--date-marker-color: rgba(84, 52, 39, 0.5);

	/* Color of the unread message marker, text and separator */
	--unread-marker-color: rgba(125, 35, 25, 0.65);

	/* Color of highlight messages */
	--highlight-fg-color: #da0000;
	--highlight-border-color: #da0000;

	/* Color for various borders */
	--separator-border-color: #bfb2b2;
}

/* Samsung Internet <7.0 and Microsoft Edge support (yes, both of them use webkit prefix) */
::-webkit-input-placeholder {
	color: rgba(0, 0, 0, 0.35);
}

::placeholder {
	color: rgba(0, 0, 0, 0.35);
	opacity: 1; /* fix opacity in Firefox */
}

html,
body {
	height: 100%;
	overscroll-behavior: none; /* prevent overscroll navigation actions */
}

body {
	background: var(--body-bg-color);
	color: var(--body-color);
	font: 16px -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	margin: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
	touch-action: none;

	/**
	  * Disable pull-to-refresh on mobile that conflicts with scrolling the message list.
	  * See http://stackoverflow.com/a/29313685/1935861
	  */
	overflow-y: hidden;
}

/**
 * From Normalize. See https://github.com/thelounge/thelounge/pull/1217
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
	border-bottom: none; /* 1 */
	text-decoration: underline; /* 2 */
	text-decoration: underline dotted; /* 2 */
}

h1,
h2,
h3 {
	font: inherit;
	line-height: inherit;
	margin: 0;
}

input {
	outline: 0;
}

button {
	border: none;
	background: none;
	margin: 0;
	outline: none;
	padding: 0;
}

code,
pre,
#chat .msg.motd .text,
.irc-monospace,
textarea#user-specified-css-input {
	font-family: Consolas, Menlo, Monaco, "Lucida Console", "DejaVu Sans Mono", "Courier New", monospace;
}

code,
.irc-monospace {
	font-size: 13px;
	padding: 2px 4px;
	color: #e74c3c;
	background-color: #f9f2f4;
	border-radius: 2px;
}

pre {
	display: block;
	padding: 9.5px;
	margin: 0 0 10px;
	font-size: 13px;
	line-height: 1.42857143;
	color: #333;
	word-break: break-all;
	word-wrap: break-word;
	background-color: #f5f5f5;
	border-radius: 4px;
}

kbd {
	display: inline-block;
	font-family: inherit;
	line-height: 1em;
	min-width: 28px; /* Ensure 1-char keys have the same width */
	margin: 0 1px;
	padding: 4px 6px;
	color: #444;
	text-align: center;
	text-shadow: 0 1px 0 #fff;
	background-color: white;
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05), transparent);
	border: 1px solid #bbb;
	border-radius: 4px;
	box-shadow: 0 2px 0 #bbb, inset 0 1px 1px #fff, inset 0 -1px 3px #ccc;
}

.btn {
	border: 2px solid #84ce88;
	border-radius: 3px;
	color: #84ce88;
	display: inline-block;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 1px;
	margin-bottom: 10px;
	padding: 9px 17px;
	text-transform: uppercase;
	transition: background 0.2s, border-color 0.2s, color 0.2s;
	word-spacing: 3px;
	cursor: pointer; /* This is useful for `<button>` elements */
}

.btn-small {
	padding: 5px 13px;
}

.btn:disabled,
.btn:hover,
.btn:focus {
	background: #84ce88;
	color: #fff;
	opacity: 1;
}

.btn:active {
	box-shadow: none;
	opacity: 0.8;
}

.btn:disabled {
	opacity: 0.6;
}

.btn-sm {
	padding: 4px 8px;
	border-width: 1px;
	letter-spacing: 0;
	word-spacing: 0;
	text-transform: none;
}

.container {
	margin-bottom: 20px;
	max-width: 480px;
	touch-action: pan-y;
}

#js-copy-hack,
#loading pre,
#help .container,
#changelog .container,
#windows .header .title,
#windows .header .topic,
#chat .messages {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
	cursor: text;
}

#js-copy-hack {
	position: absolute;
	left: -999999px;
}

#chat #js-copy-hack .condensed:not(.closed) .msg,
#chat #js-copy-hack > .msg {
	display: block;
}

/* Icons */

#viewport .lt::before,
#viewport .rt::before,
#chat button.menu::before,
#sidebar .chan::before,
#footer .icon,
#chat .count::before,
#settings .extra-experimental,
#settings .extra-help,
#settings #play::before,
#form #upload::before,
#form #submit::before,
#chat .away .from::before,
#chat .back .from::before,
#chat .invite .from::before,
#chat .join .from::before,
#chat .kick .from::before,
#chat .part .from::before,
#chat .quit .from::before,
#chat .topic .from::before,
#chat .mode .from::before,
#chat .motd .from::before,
#chat .ctcp .from::before,
#chat .ctcp_request .from::before,
#chat .whois .from::before,
#chat .nick .from::before,
#chat .action .from::before,
#chat .toggle-button::after,
#chat .toggle-content .more-caret::before,
#version-checker::before,
.context-menu-item::before,
#help .website-link::before,
#help .documentation-link::before,
#help .report-issue-link::before,
#image-viewer .previous-image-btn::before,
#image-viewer .next-image-btn::before,
#sidebar .not-connected-icon::before,
#sidebar .collapse-network-icon::before {
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit; /* Can't have font-size inherit on line above, so need to override */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#viewport .lt::before { content: "\F0C9"; /* http://fontawesome.io/icon/bars/ */ }
#viewport .rt::before { content: "\F0C0"; /* https://fontawesome.com/icons/users?style=solid */ }
#chat button.menu::before { content: "\F142"; /* http://fontawesome.io/icon/ellipsis-v/ */ }

.context-menu-join::before { content: "\F067"; /* http://fontawesome.io/icon/plus/ */ }
.context-menu-user::before { content: "\F007"; /* http://fontawesome.io/icon/user/ */ }
.context-menu-close::before { content: "\F00D"; /* http://fontawesome.io/icon/times/ */ }
.context-menu-list::before { content: "\F03A"; /* http://fontawesome.io/icon/list/ */ }
.context-menu-disconnect::before { content: "\F0C1"; /* https://fontawesome.com/icons/unlink?style=solid */ }
.context-menu-connect::before { content: "\F127"; /* https://fontawesome.com/icons/link?style=solid */ }
.context-menu-action-whois::before { content: "\F05A"; /* http://fontawesome.io/icon/info-circle/  */ }
.context-menu-action-kick::before { content: "\F05E"; /* http://fontawesome.io/icon/ban/ */ }
.context-menu-action-op::before { content: "\F1FA"; /* http://fontawesome.io/icon/at/ */ }
.context-menu-action-voice::before { content: "\F067"; /* http://fontawesome.io/icon/plus/ */ }
.context-menu-network::before { content: "\F233"; /* https://fontawesome.com/icons/server?style=solid */ }
.context-menu-edit::before { content: "\F303"; /* https://fontawesome.com/icons/pencil-alt?style=solid */ }

#sidebar .not-connected-icon::before {
	content: "\F127"; /* https://fontawesome.com/icons/unlink?style=solid */
}

.context-menu-query::before,
.context-menu-action-query::before,
#sidebar .chan.query::before { content: "\F075"; /* https://fontawesome.com/icons/comment?style=solid */ }

.context-menu-chan::before,
#sidebar .chan.channel::before { content: "\F086"; /* http://fontawesome.io/icon/comments/ */ }

#sidebar .chan.special::before { content: "\F03A"; /* http://fontawesome.io/icon/list/ */ }

#footer .sign-in::before { content: "\F023"; /* http://fontawesome.io/icon/lock/ */ }
#footer .connect::before { content: "\F067"; /* http://fontawesome.io/icon/plus/ */ }
#footer .settings::before { content: "\F013"; /* http://fontawesome.io/icon/cog/ */ }
#footer .help::before { content: "\F059"; /* http://fontawesome.io/icon/question/ */ }

#form #upload::before { content: "\F0C6"; /* https://fontawesome.com/icons/paperclip?style=solid */ }
#form #submit::before { content: "\F1D8"; /* http://fontawesome.io/icon/paper-plane/ */ }

#chat .away .from::before,
#chat .back .from::before {
	content: "\F017"; /* https://fontawesome.com/icons/clock?style=solid */
	color: #7f8c8d;
}

#help .website-link::before,
#help .documentation-link::before,
#help .report-issue-link::before,
#chat .toggle-button {
	display: inline-block;
	margin-right: 5px;

	/* These 2 directives are loosely taken from .fa-fw */
	width: 1.35em;
	text-align: center;
}

#help .website-link::before { content: "\F0AC"; /* http://fontawesome.io/icon/globe/ */ }
#help .documentation-link::before { content: "\F19D"; /* http://fontawesome.io/icon/graduation-cap/ */ }
#help .report-issue-link::before { content: "\F188"; /* http://fontawesome.io/icon/bug/ */ }

.session-list strong {
	display: block;
}

.session-list p {
	margin-bottom: 10px;
}

#chat .invite .from::before {
	content: "\F0E0"; /* https://fontawesome.com/icons/envelope?style=solid */
	color: #2ecc40;
}

#chat .part .from::before,
#chat .quit .from::before {
	content: "\F2F5"; /* https://fontawesome.com/icons/sign-out-alt?style=solid */
	color: #ff4136;
	display: inline-block;
	transform: rotate(180deg);
}

#chat .topic .from::before {
	content: "\F0A1"; /* http://fontawesome.io/icon/bullhorn/ */
	color: #2ecc40;
}

#chat .mode .from::before {
	content: "\F05A"; /* http://fontawesome.io/icon/info-circle/ */
	color: #2ecc40;
}

#chat .motd .from::before {
	content: "\F02E"; /* https://fontawesome.com/icons/bookmark?style=solid */
	color: var(--body-color-muted);
}

#chat .ctcp .from::before,
#chat .ctcp_request .from::before {
	content: "\F15C"; /* https://fontawesome.com/icons/file-alt?style=solid */
	color: var(--body-color-muted);
}

#chat .whois .from::before {
	content: "\F007"; /* http://fontawesome.io/icon/user/ */
	color: #2ecc40;
}

#chat .nick .from::before {
	content: "\F007"; /* http://fontawesome.io/icon/user/ */
	color: #2ecc40;
}

#chat .join .from::before {
	content: "\F2F6"; /* https://fontawesome.com/icons/sign-in-alt?style=solid */
	color: #2ecc40;
}

#chat .kick .from::before {
	content: "\F05E"; /* http://fontawesome.io/icon/ban/ */
	color: #ff4136;
}

#chat .action .from::before {
	content: "\F005"; /* http://fontawesome.io/icon/star/ */
}

#chat .toggle-button {
	display: inline-block;
	transition: opacity 0.2s, transform 0.2s;

	/* These 2 directives are loosely taken from .fa-fw */
	width: 1.35em;
	text-align: center;
}

#chat .toggle-button::after {
	content: "\F0DA"; /* http://fontawesome.io/icon/caret-right/ */
}

#chat .count::before {
	content: "\F002"; /* http://fontawesome.io/icon/search/ */
	position: absolute;
	right: 13px;
	line-height: 45px;
}

#settings .extra-experimental::before {
	content: "\F0C3"; /* https://fontawesome.com/icons/flask?style=solid */
}

#settings .extra-help::before {
	content: "\F059"; /* http://fontawesome.io/icon/question-circle/ */
}

#settings #play::before {
	content: "\F028"; /* http://fontawesome.io/icon/volume-up/ */
	margin-right: 9px;
}

#image-viewer .previous-image-btn::before {
	content: "\F104"; /* http://fontawesome.io/icon/angle-left/ */
}

#image-viewer .next-image-btn::before {
	content: "\F105"; /* http://fontawesome.io/icon/angle-right/ */
}

/* End icons */

#viewport {
	display: flex;
	height: 100%;
}

#windows {
	flex: 1 0 auto;
	position: relative;
}

#form button,
.header button,
.reveal-password span {
	transition: opacity 0.2s;
}

#form button:hover,
.header button:hover,
.reveal-password span:hover {
	opacity: 0.6;
}

#viewport .lt,
#viewport .rt,
#chat button.menu {
	color: #607992;
	display: flex;
	font-size: 15px;
	line-height: 1;
	height: 36px;
	width: 36px;
	margin-top: 6px;
	flex-shrink: 0;
}

#viewport .lt::before,
#viewport .rt::before,
#chat button.menu::before {
	width: 36px;
	line-height: 36px; /* Fix alignment in Microsoft Edge */
}

/* Channel list button stays fixed when scrolling... */
#viewport .lt {
	position: fixed;
}

/* ... Except on chat windows, relative to include the notification dot */
#viewport #chat .lt {
	position: relative;
}

/* Notification dot on the top right corner of the menu icon */
#viewport .lt::after {
	content: "";
	position: absolute;
	top: 9px;
	right: 7px;
	background-color: #e74c3c;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 2px solid var(--window-bg-color);
	opacity: 0;
	transition: opacity 0.2s;
	background-clip: padding-box; /* Fix border-radius bleeding color */
}

#viewport.notified .lt::after {
	opacity: 1;
}

#viewport.userlist-open #chat .userlist {
	display: flex;
}

#sidebar {
	display: none;
	flex-direction: column;
	width: 220px;
	will-change: transform;
}

#viewport.menu-open #sidebar {
	display: flex;
}

#sidebar .scrollable-area {
	overflow-x: auto;
	flex-grow: 1;
	touch-action: pan-y;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
}

#sidebar .chan,
#sidebar .empty {
	font-size: 15px;
}

#sidebar .network.collapsed .chan:not(.lobby) {
	display: none;
}

#sidebar .chan {
	cursor: pointer;
}

/* All sidebar buttons and channels/queries must be white on hover and active */
#sidebar button:hover,
#sidebar .chan:hover,
#sidebar .active {
	color: #fff;
}

/* All lobbies/channels/queries and footer buttons must have a half-transparent
background on hover (unless active) */
#sidebar .chan:hover,
#footer button:hover {
	background-color: rgba(48, 62, 74, 0.5); /* #303e4a x 50% alpha */
}

/* All active elements, hovered or not, must have a background */
#sidebar .active,
#sidebar .active:hover {
	background-color: #303e4a;
}

/* Remove background on hovered/active channel when sorting/drag-and-dropping */
#sidebar .ui-sortable-helper .chan.active, /* Networks */
#sidebar .ui-sortable-helper .chan:hover,
#sidebar .chan.ui-sortable-helper.active, /* Channels */
#sidebar .chan.ui-sortable-helper:hover {
	background-color: transparent;
}

#sidebar .networks {
	padding-top: 5px;
}

#sidebar .networks:empty {
	display: none;
}

#sidebar .network,
#sidebar .network-placeholder {
	position: relative;
	margin-bottom: 20px;
	touch-action: pan-y;
}

#sidebar .empty {
	flex-grow: 1;
	line-height: 1.6;
	padding: 40px 20px;
	text-align: center;
}

#sidebar .chan,
#sidebar .chan-placeholder {
	display: flex;
	padding: 2px 14px;
}

#sidebar .network-placeholder,
#sidebar .chan-placeholder {
	border: 1px dashed #99a2b4;
	border-radius: 6px;
	margin: -1px 10px;
}

#sidebar .network-placeholder {
	margin-bottom: 29px;
}

#sidebar .chan.lobby {
	font-size: 15px;
	font-weight: bold;
	padding-left: 0;
}

#sidebar .lobby-wrap {
	display: flex;
	flex-grow: 1;
	overflow: hidden;
}

#sidebar .chan.lobby .lobby-wrap:hover,
#sidebar .chan.lobby.active {
	color: #c0f8c3;
}

#sidebar .not-connected-tooltip {
	display: none;
	margin: 0 8px;
}

#sidebar .not-connected .not-connected-tooltip {
	display: inline-block;
}

#sidebar .not-connected .chan.lobby {
	color: #e74c3c;
}

#sidebar .not-connected .chan.lobby .lobby-wrap:hover,
#sidebar .not-connected .chan.lobby.active {
	color: #f1978e;
}

#sidebar .chan::before {
	width: 14px;
	margin-right: 12px;
	line-height: 18px;
}

#sidebar .chan .name {
	position: relative;
	flex-grow: 1;
	overflow: hidden;
	white-space: nowrap;
	margin-right: 5px;
}

#windows .header .topic,
#sidebar .chan .name {
	-webkit-mask-image: linear-gradient(to left, transparent, black 20px);
	mask-image: linear-gradient(to left, transparent, black 20px);
}

#sidebar .badge,
#sidebar .add-channel-tooltip,
#sidebar .close-tooltip {
	flex-shrink: 0;
	line-height: 1;
}

#sidebar .badge {
	background: rgba(255, 255, 255, 0.06);
	border-radius: 3px;
	font-size: 10px;
	padding: 4px 6px;
	transition: background-color 0.2s, color 0.2s;
}

#sidebar .badge:empty {
	display: none;
}

#sidebar .badge.highlight {
	background: #fff;
	color: #49505a;
}

#sidebar .close {
	width: 18px;
	height: 18px;
	display: none;
	transition: opacity 0.2s, background-color 0.2s;
}

#sidebar .close::before {
	font-size: 20px;
	font-weight: normal;
	display: inline-block;
	line-height: 16px;
	text-align: center;
	content: "\D7";
	color: #fff;
}

#sidebar .lobby .add-channel {
	border-radius: 3px;
	width: 18px;
	height: 18px;
	opacity: 0.4;
	transition: opacity 0.2s, background-color 0.2s, transform 0.2s;
}

#sidebar .lobby .add-channel::before {
	font-size: 20px;
	font-weight: normal;
	display: inline-block;
	line-height: 16px;
	text-align: center;
	content: "+";
}

#sidebar .lobby .add-channel:hover {
	opacity: 1;
}

#sidebar .lobby .add-channel.opened {
	/* translateZ(0) enables hardware acceleration, this is to avoid jittering when animating */
	transform: rotate(45deg) translateZ(0);
}

#sidebar .network .lobby:nth-last-child(2) .collapse-network {
	/* Hide collapse button if there are no channels/queries */
	width: 0;
	overflow: hidden;
}

#sidebar .network .collapse-network {
	width: 40px;
	opacity: 0.4;
	padding-left: 11px;
	transition: opacity 0.2s;
}

#sidebar .network .collapse-network-icon {
	display: block;
	width: 20px;
	height: 20px;
	transition: transform 0.2s;
}

#sidebar .network.collapsed .collapse-network-icon {
	transform: rotate(-90deg);
}

#sidebar .network .collapse-network-icon::before {
	content: "\F0D7"; /* http://fontawesome.io/icon/caret-down/ */
}

#sidebar .collapse-network:hover {
	opacity: 1;
}

#sidebar .chan.active .close {
	opacity: 0.4;
	display: unset;
}

#sidebar .chan.active .close:hover {
	opacity: 1;
}

#footer {
	height: 45px;
	font-size: 15px;
	flex-shrink: 0;
	display: flex;
	justify-content: center;
}

#footer button {
	display: inline-block;
	width: 45px;
	height: 100%;
	border-radius: 5px;
}

.signed-out #footer .sign-in {
	display: inline-block;
}

.signed-out #footer .connect {
	display: none;
}

.public #footer .sign-in {
	display: none;
}

#footer .sign-in {
	display: none;
}

.signed-out #sidebar {
	display: none; /* Hide the sidebar when user is signed out */
}

#windows li,
#windows p,
#windows label,
#settings .error {
	font-size: 15px;
}

.input {
	background-color: white;
	border: 1px solid #cdd3da;
	border-radius: 2px;
	color: #222;
	font-size: 15px;
	margin: 2px 0;
	margin-bottom: 10px;
	outline: 0;
	padding: 0 10px;
	transition: border-color 0.2s;
	width: 100%;
	height: 35px;
	line-height: 35px;
}

.input:disabled {
	background-color: #ddd;
}

.input:not(:disabled):hover,
.input:not(:disabled):focus {
	border-color: #79838c;
}

#user-specified-css-input {
	resize: vertical;
	height: 10em;
	padding: 6px 10px;
	line-height: 1.5;
}

#windows .window {
	background: var(--window-bg-color);
	display: none;
	overflow-y: auto;
	height: 100%;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
}

#chat .chan,
#windows .window {
	/* flexbox does not seem to scroll without doing this */
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
}

#windows .window h1 {
	font-size: 36px;
}

#windows .window h2 {
	font-size: 22px;
	margin: 30px 0 10px;
	padding-bottom: 7px;
}

#windows .window h2 small {
	color: inherit;
	line-height: 30px;
}

#windows .window h3 {
	font-size: 18px;
	margin: 20px 0 10px;
}

#windows .window.active {
	display: flex;
	flex-direction: column;
}

#windows .header {
	line-height: 45px;
	height: 45px;
	padding: 0 6px;
	display: flex;
	flex-shrink: 0;
	overflow: hidden;
}

#windows #chat .header {
	border-bottom: 1px solid var(--separator-border-color);
}

#windows .header .title {
	font-size: 15px;
	padding-left: 6px;
	flex-shrink: 0;
}

#windows .header .topic {
	margin-left: 8px;
	word-break: break-all;
	flex-grow: 1;
	overflow: hidden;
	font-size: 15px;
}

#chat {
	overflow: hidden;
	flex: 1 0 auto;
	position: relative;
}

#chat .chan {
	display: none;
	flex-direction: column;
}

#chat .chan.active {
	display: flex;
}

#chat .condensed {
	flex-wrap: wrap;
}

#chat .condensed .content {
	flex: 1;
}

/* Ensures expanded status messages always take up the full width */
#chat .condensed .msg {
	flex-basis: 100%;
}

#chat .condensed-summary .content {
	display: block;
	cursor: pointer;
	transition: opacity 0.2s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#chat .condensed-summary {
	display: none;
}

#chat.condensed-status-messages .condensed-summary {
	display: flex;
}

#chat .condensed-summary .content:hover {
	opacity: 0.6;
}

#chat .condensed-summary .toggle-button:hover {
	opacity: 1;
}

#chat.condensed-status-messages .condensed.closed .msg {
	display: none;
}

#chat .condensed-summary .time {
	visibility: hidden;
}

#windows #form,
.messages .msg,
.userlist {
	font-size: 15px;
	line-height: 1.3;
}

#chat .chat-content {
	display: flex;
	flex-grow: 1;
	overflow: hidden;
	position: relative;
}

#chat .chat {
	overflow: auto;
	overflow-x: hidden;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
}

#chat .userlist {
	border-left: 1px solid var(--separator-border-color);
	width: 180px;
	display: none;
	flex-direction: column;
	flex-shrink: 0;
	touch-action: pan-y;
}

/**
  * Toggled via JavaScript
  */
#sidebar .join-form {
	display: none;
	padding: 0 18px 8px;
}

#sidebar .join-form .input {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 5px;
	margin-bottom: 5px;
}

#sidebar .join-form .btn {
	display: block;
	width: 100%;
	margin: auto;
}

#chat .show-more {
	display: none;
	padding: 10px;
	padding-top: 15px;
	padding-bottom: 0;
	width: 100%;
}

#chat .show-more .btn {
	width: 100%;
	margin: 0;
}

#chat .messages {
	padding: 10px 0;
	touch-action: pan-y;
}

#chat .chan:not(.special) .messages {
	margin-top: auto;
}

#chat .msg {
	word-wrap: break-word;
	word-break: break-word; /* Webkit-specific */
	display: flex;
	align-items: flex-start;
	position: relative;
}

#chat .unread-marker {
	position: relative;
	text-align: center;
	margin: 0 10px;
	z-index: 0;
	font-weight: bold;
	font-size: 12px;
}

#chat .unread-marker::before {
	position: absolute;
	z-index: -1;
	content: "";
	left: 0;
	right: 0;
	top: 50%;
	border-top: 1px solid var(--separator-border-color);
}

#chat .unread-marker-text::before {
	content: "New messages";
	background-color: var(--window-bg-color);
	color: var(--unread-marker-color);
	padding: 0 10px;
}

#chat .unread-marker:last-child {
	display: none;
}

#chat .date-marker {
	position: relative;
	text-align: center;
	margin: 0 10px;
	z-index: 0;
	font-weight: bold;
	font-size: 12px;
}

#chat .date-marker::before {
	position: absolute;
	z-index: -1;
	content: "";
	left: 0;
	right: 0;
	top: 50%;
	border-top: 1px solid var(--separator-border-color);
}

#chat .date-marker-text::before {
	content: attr(data-label);
	background-color: var(--window-bg-color);
	color: var(--date-marker-color);
	padding: 0 10px;
}

#chat .time,
#chat .from,
#chat .content {
	flex: 0 0 auto;
}

#chat .time {
	color: var(--body-color-muted);
	padding-left: 10px;
	font-variant-numeric: tabular-nums;
}

#chat .from {
	padding-right: 10px;
	text-align: right;
	width: 134px;
	overflow: hidden;
	white-space: nowrap;
	position: relative;
}

#chat .content {
	flex: 1 1 auto;
	min-width: 0;
	padding-left: 10px;
	padding-right: 6px;
	border-left: 1px solid var(--separator-border-color);
	overflow: hidden; /* Prevents Zalgo text to expand beyond messages */
}

#chat .unhandled .from {
	color: var(--body-color-muted);
}

#chat .special .time,
#chat .special .from {
	display: none;
}

#chat .special .date-marker-container,
#chat .special .unread-marker {
	display: none;
}

#chat .special table th {
	word-break: normal;
}

/* Nicknames */

#chat .user {
	color: #50a656;
}

#chat.colored-nicks .user.color-19 { color: #206a20; }
#chat.colored-nicks .user.color-20 { color: #a23131; }
#chat.colored-nicks .user.color-22 { color: #80267f; }
#chat.colored-nicks .user.color-24 { color: #755617; }
#chat.colored-nicks .user.color-25 { color: #175917; }
#chat.colored-nicks .user.color-26 { color: #195555; }
#chat.colored-nicks .user.color-27 { color: #215f5f; }
#chat.colored-nicks .user.color-28 { color: #1d1dd3; }
#chat.colored-nicks .user.color-29 { color: #822882; }

#chat .msg.channel_list_loading .text {
	color: #999;
	font-style: italic;
	padding-left: 20px;
}

#chat .msg.channel_list_truncated .text {
	color: #f00;
	padding-left: 20px;
}

#chat table.channel-list,
#chat table.ban-list,
#chat table.ignore-list {
	margin: 5px 10px;
	width: calc(100% - 30px);
}

#chat table.channel-list th,
#chat table.ban-list th,
#chat table.ignore-list th,
#chat table.channel-list td,
#chat table.ban-list td,
#chat.table.ignore-list td {
	padding: 5px;
	vertical-align: top;
	border-bottom: #eee 1px solid;
}

#chat table.channel-list .channel {
	width: 80px;
}

#chat table.channel-list .channel,
#chat table.channel-list .topic,
#chat table.ban-list .hostmask,
#chat table.ban-list .banned_by,
#chat table.ban-list .banned_at,
#chat table.ignore-list .hostmask,
#chat table.ignore-list .when {
	text-align: left;
}

#chat table.channel-list .users {
	text-align: center;
	width: 50px;
}

#chat.hide-status-messages .condensed,
#chat.hide-motd .motd {
	display: none !important;
}

#chat .msg.motd .text {
	background: #f6f6f6;
	display: inline-block;
	border-radius: 4px;
	padding: 6px;
}

#chat .condensed .content,
#chat .away .content,
#chat .back .content,
#chat .join .content,
#chat .kick .content,
#chat .mode .content,
#chat .nick .content,
#chat .part .content,
#chat .quit .content,
#chat .topic .content,
#chat .topic_set_by .content {
	color: var(--body-color-muted);
}

#chat .notice .text,
#chat .chan .notice .user {
	color: #0074d9 !important;
}

#chat .notice .user::before {
	content: "Notice: ";
}

#chat .error,
#chat .error .from {
	color: #e74c3c;
}

#chat .channel .message.highlight {
	color: var(--highlight-fg-color);
	border-left: 5px solid var(--highlight-border-color);
}

#chat .channel .msg.highlight .time {
	padding-left: 5px;
}

#chat .toggle-content.opened .more-caret, /* Expand/Collapse link previews */
#chat .toggle-button.opened, /* Thumbnail toggle */
#chat .msg.condensed:not(.closed) .toggle-button { /* Expanded status message toggle */
	transform: rotate(90deg);
}

#chat .toggle-content {
	background: #f6f6f6;
	border-radius: 5px;
	display: none;
	max-width: 100%;
	margin: 0;
	margin-top: 6px;
	overflow: hidden;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

/* This applies to images of preview-type-image and thumbnails of preview-type-link */
#chat .toggle-content img {
	max-width: 100%;
	max-height: 128px;
	display: block;
	cursor: zoom-in;
}

#chat .toggle-content pre.prefetch-error {
	padding: 0;
	margin: 0;
	color: inherit;
	background-color: transparent;
}

#chat .toggle-content .prefetch-error {
	display: none;
}

#chat .toggle-content.opened .prefetch-error {
	display: inline;
}

/* This applies to thumbnails of preview-type-link only */
#chat .toggle-content .thumb {
	max-height: 54px;
	max-width: 96px;
}

#chat .toggle-type-error,
#chat .toggle-content .toggle-text {
	padding: 8px 10px;
}

#chat .toggle-content .toggle-text {
	white-space: nowrap;
	overflow: hidden;
}

#chat .toggle-content.opened .toggle-text {
	white-space: normal;
}

#chat .toggle-content .head {
	display: flex;
	align-items: flex-start;
	font-weight: bold;
}

#chat .toggle-type-error,
#chat .toggle-text .body {
	color: #717171;
}

#chat .toggle-text a {
	color: inherit;
}

#chat .toggle-text .overflowable {
	text-overflow: ellipsis;
	overflow: hidden;
	flex-grow: 1;
}

#chat .toggle-content .more {
	color: var(--link-color);
	font-weight: normal;
	margin-left: 10px;
	flex-shrink: 0;
}

#chat .toggle-content .more:hover {
	text-decoration: underline;
}

#chat .toggle-content .more::after {
	content: " " attr(aria-label);
}

#chat .toggle-content .more-caret {
	display: inline-block;
	transition: transform 0.2s;
}

#chat .toggle-content .more-caret::before {
	content: "\F0DA"; /* https://fontawesome.com/icons/caret-right?style=solid */
}

#chat .toggle-content.show {
	display: inline-flex !important;
	align-items: flex-start;
}

#chat audio {
	width: 600px;
	max-width: 100%;
}

#chat video {
	max-width: 640px;
	max-height: 240px;
}

/* Do not display an empty div when there are no previews. Useful for example in
part/quit messages where we don't load previews (adds a blank line otherwise) */
#chat .preview:empty {
	display: none;
}

#chat .userlist .count {
	height: 45px;
	flex-shrink: 0;
	position: relative;
}

#chat .userlist .search {
	color: var(--body-color);
	border: 0;
	border-bottom: 1px solid var(--separator-border-color);
	background: none;
	font: inherit;
	outline: 0;
	padding: 13px;
	width: 100%;
}

#chat .userlist .names {
	flex-grow: 1;
	overflow: auto;
	overflow-x: hidden;
	padding-bottom: 10px;
	width: 100%;
	touch-action: pan-y;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
}

#chat .names-filtered {
	display: none;
}

#chat .names .user {
	display: block;
	line-height: 1.4;
	padding: 0 16px;
	white-space: nowrap;
}

#chat .user-mode {
	margin-bottom: 15px;
}

#chat .user-mode::before {
	background: var(--window-bg-color);
	color: var(--body-color-muted);
	display: block;
	font-size: 0.85em;
	line-height: 1.6;
	padding: 5px 16px;
	position: sticky;
	top: 0;
}

#chat .user-mode.owner::before {
	content: "Owners";
}

#chat .user-mode.admin::before {
	content: "Administrators";
}

#chat .user-mode.op::before {
	content: "Operators";
}

#chat .user-mode.half-op::before {
	content: "Half-Operators";
}

#chat .user-mode.voice::before {
	content: "Voiced";
}

#chat .user-mode.normal::before {
	content: "Users";
}

#chat .user-mode-search::before {
	content: "Search Results";
}

#loading.active {
	font-size: 15px;
	z-index: 1;
	display: flex;
	flex-direction: column;
}

#loading p {
	margin-top: 10px;
}

#loading-slow,
#loading-reload {
	visibility: hidden;
}

#loading summary {
	outline: none;
	cursor: pointer;
}

#loading pre {
	text-align: left;
	white-space: normal;
}

#sign-in .container,
#loading-reload-container,
#loading-status-container {
	flex: 1 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
}

#loading-reload-container {
	flex-grow: 0;
}

#sign-in label {
	display: block;
	margin-top: 10px;
	width: 100%;
}

#sign-in .btn {
	margin-top: 25px;
}

#sign-in .error {
	color: #e74c3c;
	display: none; /* This message gets displayed on error only */
	margin-top: 1em;
	width: 100%;
}

#connect label {
	display: block;
	margin-top: 11px;
}

#connect .port::before {
	content: ":";
	margin: 9px 0 0 -17px;
	position: absolute;
}

#connect .tls {
	float: left;
	margin-top: 6px;
}

#connect .tls input {
	float: left;
	margin: 3px 10px 0 0;
}

#connect .btn {
	float: left;
	margin-top: 30px;
}

#settings .sync-warning-base {
	display: none;
}

#settings .opt {
	display: block;
	padding: 5px 0 5px 1px;
}

#settings .opt input {
	margin-right: 6px;
}

#settings .extra-experimental {
	color: #84ce88;
}

#settings .extra-help,
#settings #play {
	color: #7f8c8d;
}

#settings .extra-experimental,
#settings .extra-help {
	cursor: help;
}

#settings .extra-experimental,
#settings h2 .extra-help {
	font-size: 0.8em;
}

#settings #play {
	font-size: 15px;
	transition: opacity 0.2s;
}

#settings #play:hover {
	opacity: 0.8;
}

#settings #change-password .error,
#settings #change-password .success {
	margin-bottom: 1em;
}

#settings #change-password .error {
	color: #e74c3c;
}

#settings #change-password .success {
	color: #2ecc40;
}

#settings .error {
	color: #e74c3c;
	margin-top: 0.2em;
}

.password-container {
	position: relative;
}

.password-container input {
	padding-right: 37px;
}

#sign-in .password-container {
	width: 100%;
}

#sign-in .password-container .reveal-password {
	top: 31px;
	right: 0;
}

.password-container .reveal-password {
	position: absolute;
	top: 2px;
	right: 15px;
}

.password-container .reveal-password span {
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 16px;
	color: #607992;
	width: 35px;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.password-container .reveal-password span::before {
	content: "\F06E"; /* https://fontawesome.com/icons/eye?style=solid */
}

.password-container .reveal-password.visible span::before {
	content: "\F070"; /* https://fontawesome.com/icons/eye-slash?style=solid */
	color: #ff4136;
}

#help .help-item {
	display: table-row;
	font-size: 15px;
}

#help .help-item .subject,
#help .help-item .description {
	display: table-cell;
	padding-bottom: 15px;
}

#help .help-item .subject {
	white-space: nowrap;
	padding-right: 15px;
}

#help .help-item .description p {
	margin-bottom: 0;
}

.is-apple #help .key-all,
#help .key-apple {
	display: none;
}

.is-apple #help .key-apple {
	display: inline-block;
}

.whois {
	display: -ms-grid; /* Edge 15- */
	display: grid;
	-ms-grid-template-columns: max-content auto;
	grid-template-columns: max-content auto;
	margin: 0;
}

.whois dt {
	-ms-grid-column-start: 1;
	grid-column-start: 1;
	margin-right: 20px;
}

.whois dd {
	-ms-grid-column-start: 2;
	grid-column-start: 2;
}

.changelog-text {
	line-height: 1.5;
}

.changelog-text p {
	margin-bottom: 16px;
}

.window#changelog h3 {
	font-size: 20px;
	border-bottom: 1px solid #7f8c8d;
	color: #7f8c8d;
	margin: 30px 0 10px;
	padding-bottom: 7px;
}

#version-checker {
	display: flex;
	align-items: center;
	padding: 10px;
	margin-bottom: 16px;
	border-radius: 2px;
	transition: color 0.2s, background-color 0.2s;
}

#version-checker p,
#version-checker button {
	margin-bottom: 0;
}

#version-checker p {
	flex: 1;
	padding-top: 6px;
	padding-bottom: 6px;
}

#version-checker::before {
	margin-left: 6px;
	margin-right: 12px;
	font-size: 1.2em;
}

#version-checker.loading {
	background-color: #d9edf7;
	color: #31708f;
}

#version-checker.loading::before {
	content: "\F253"; /* https://fontawesome.com/icons/hourglass-end?style=solid */
}

#version-checker.new-version {
	color: #8a6d3b;
	background-color: #fcf8e3;
}

#version-checker.new-version::before {
	content: "\F164"; /* https://fontawesome.com/icons/thumbs-up?style=solid */
}

#version-checker.error {
	color: #a94442;
	background-color: #f2dede;
}

#version-checker.error::before {
	content: "\F06A"; /* http://fontawesome.io/icon/exclamation-circle/ */
}

#version-checker.up-to-date {
	background-color: #dff0d8;
	color: #3c763d;
}

#version-checker.up-to-date #check-now {
	/* "Check now" button is hidden until data expires */
	display: none;
}

#version-checker.up-to-date::before {
	content: "\F00C"; /* http://fontawesome.io/icon/check/ */
}

#upload-progressbar {
	background: blue;
	width: 0%;
	height: 2px;
}

#form {
	flex: 0 0 auto;
	border: 0;
	border-top: 1px solid var(--separator-border-color);
	border-radius: 0;
	margin: 0;
	padding: 6px;
	display: flex;
	align-items: flex-end;
}

#connection-error {
	font-size: 12px;
	line-height: 36px;
	font-weight: bold;
	letter-spacing: 1px;
	word-spacing: 3px;
	text-transform: uppercase;
	background: #e74c3c;
	color: #fff;
	text-align: center;
	display: none;
}

#connection-error.shown {
	display: block;
	cursor: pointer;
}

#form #nick {
	background: #f6f6f6;
	color: #666;
	font-size: 13px;
	margin: 4px;
	line-height: 24px;
	padding: 0 8px;
	border-radius: 2px;
	display: none;
}

.public #form #nick {
	display: block;
}

#form #input {
	background: transparent;
	border: none;
	font: inherit;
	min-height: 19px; /* Required when computing input height at char deletion */
	height: 19px;
	max-height: 95px; /* min-height/height x number of lines maximum */
	line-height: 19px /* should match height */;
	outline: none;
	margin: 5px;
	padding: 0;
	resize: none;
	flex: 1 0 auto;
	align-self: center;
	touch-action: pan-y;
}

#form #upload-input {
	display: none;
}

#form #upload,
#form #submit {
	color: #607992;
	font-size: 15px;
	height: 32px;
	width: 32px;
	flex: 0 0 auto;
}

#context-menu-container {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background: transparent;
}

#context-menu,
.textcomplete-menu {
	position: absolute;
	list-style: none;
	margin: 0;
	padding: 0;
	min-width: 180px;
	font-size: 15px;
	background-color: #fff;
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 2px;
}

.context-menu-divider {
	height: 1px;
	margin: 6px 0;
	background-color: rgba(0, 0, 0, 0.1);
}

.context-menu-item,
.textcomplete-item {
	cursor: pointer;
	display: block;
	padding: 4px 8px;
	color: #333;
	margin-top: 6px;
	margin-bottom: 6px;
	line-height: 1.4;
	transition: background-color 0.2s;
}

.context-menu-item:focus,
.textcomplete-item:focus,
.context-menu-item:hover,
.textcomplete-item:hover,
.textcomplete-menu .active,
#chat .userlist .user.active {
	background-color: #f6f6f6;
	transition: none;
	outline: 0; /* TODO: Handle focus outlines in PR #1873 */
}

.context-menu-item::before,
.textcomplete-item::before {
	width: 20px;
	display: inline-block;
}

.textcomplete-item a {
	color: #333;
}

.textcomplete-item a:hover {
	text-decoration: none;
}

.emoji {
	font-family: 'Apple Color Emoji';
}

.textcomplete-item .emoji {
	width: 32px;
	text-align: center;
}

.textcomplete-item .irc-bg {
	display: block;
}

/**
  * IRC Message Styling
  * Colours are credit to http://clrs.cc/
  */
.irc-fg0 { color: #fff; }
.irc-fg1 { color: #000; }
.irc-fg2 { color: #001f3f; }
.irc-fg3 { color: #2ecc40; }
.irc-fg4 { color: #ff4136; }
.irc-fg5 { color: #85144b; }
.irc-fg6 { color: #b10dc9; }
.irc-fg7 { color: #ff851b; }
.irc-fg8 { color: #ffdc00; }
.irc-fg9 { color: #01ff70; }
.irc-fg10 { color: #39cccc; }
.irc-fg11 { color: #7fdbff; }
.irc-fg12 { color: #0074d9; }
.irc-fg13 { color: #f012be; }
.irc-fg14 { color: #aaa; }
.irc-fg15 { color: #ddd; }
.irc-bg0 { background: #fff; }
.irc-bg1 { background: #000; }
.irc-bg2 { background: #001f3f; }
.irc-bg3 { background: #2ecc40; }
.irc-bg4 { background: #ff4136; }
.irc-bg5 { background: #85144b; }
.irc-bg6 { background: #b10dc9; }
.irc-bg7 { background: #ff851b; }
.irc-bg8 { background: #ffdc00; }
.irc-bg9 { background: #01ff70; }
.irc-bg10 { background: #39cccc; }
.irc-bg11 { background: #7fdbff; }
.irc-bg12 { background: #0074d9; }
.irc-bg13 { background: #f012be; }
.irc-bg14 { background: #aaa; }
.irc-bg15 { background: #ddd; }

/* https://modern.ircdocs.horse/formatting.html#colors-16-98 */
.irc-fg16 { color: #470000; }
.irc-fg17 { color: #472100; }
.irc-fg18 { color: #474700; }
.irc-fg19 { color: #324700; }
.irc-fg20 { color: #004700; }
.irc-fg21 { color: #00472c; }
.irc-fg22 { color: #004747; }
.irc-fg23 { color: #002747; }
.irc-fg24 { color: #000047; }
.irc-fg25 { color: #2e0047; }
.irc-fg26 { color: #470047; }
.irc-fg27 { color: #47002a; }
.irc-fg28 { color: #740000; }
.irc-fg29 { color: #743a00; }
.irc-fg30 { color: #747400; }
.irc-fg31 { color: #517400; }
.irc-fg32 { color: #007400; }
.irc-fg33 { color: #007449; }
.irc-fg34 { color: #007474; }
.irc-fg35 { color: #004074; }
.irc-fg36 { color: #000074; }
.irc-fg37 { color: #4b0074; }
.irc-fg38 { color: #740074; }
.irc-fg39 { color: #740045; }
.irc-fg40 { color: #b50000; }
.irc-fg41 { color: #b56300; }
.irc-fg42 { color: #b5b500; }
.irc-fg43 { color: #7db500; }
.irc-fg44 { color: #00b500; }
.irc-fg45 { color: #00b571; }
.irc-fg46 { color: #00b5b5; }
.irc-fg47 { color: #0063b5; }
.irc-fg48 { color: #0000b5; }
.irc-fg49 { color: #7500b5; }
.irc-fg50 { color: #b500b5; }
.irc-fg51 { color: #b5006b; }
.irc-fg52 { color: #f00; }
.irc-fg53 { color: #ff8c00; }
.irc-fg54 { color: #ff0; }
.irc-fg55 { color: #b2ff00; }
.irc-fg56 { color: #0f0; }
.irc-fg57 { color: #00ffa0; }
.irc-fg58 { color: #0ff; }
.irc-fg59 { color: #008cff; }
.irc-fg60 { color: #00f; }
.irc-fg61 { color: #a500ff; }
.irc-fg62 { color: #f0f; }
.irc-fg63 { color: #ff0098; }
.irc-fg64 { color: #ff5959; }
.irc-fg65 { color: #ffb459; }
.irc-fg66 { color: #ffff71; }
.irc-fg67 { color: #cfff60; }
.irc-fg68 { color: #6fff6f; }
.irc-fg69 { color: #65ffc9; }
.irc-fg70 { color: #6dffff; }
.irc-fg71 { color: #59b4ff; }
.irc-fg72 { color: #5959ff; }
.irc-fg73 { color: #c459ff; }
.irc-fg74 { color: #f6f; }
.irc-fg75 { color: #ff59bc; }
.irc-fg76 { color: #ff9c9c; }
.irc-fg77 { color: #ffd39c; }
.irc-fg78 { color: #ffff9c; }
.irc-fg79 { color: #e2ff9c; }
.irc-fg80 { color: #9cff9c; }
.irc-fg81 { color: #9cffdb; }
.irc-fg82 { color: #9cffff; }
.irc-fg83 { color: #9cd3ff; }
.irc-fg84 { color: #9c9cff; }
.irc-fg85 { color: #dc9cff; }
.irc-fg86 { color: #ff9cff; }
.irc-fg87 { color: #ff94d3; }
.irc-fg88 { color: #000; }
.irc-fg89 { color: #131313; }
.irc-fg90 { color: #282828; }
.irc-fg91 { color: #363636; }
.irc-fg92 { color: #4d4d4d; }
.irc-fg93 { color: #656565; }
.irc-fg94 { color: #818181; }
.irc-fg95 { color: #9f9f9f; }
.irc-fg96 { color: #bcbcbc; }
.irc-fg97 { color: #e2e2e2; }
.irc-fg98 { color: #fff; }
.irc-bg16 { background-color: #470000; }
.irc-bg17 { background-color: #472100; }
.irc-bg18 { background-color: #474700; }
.irc-bg19 { background-color: #324700; }
.irc-bg20 { background-color: #004700; }
.irc-bg21 { background-color: #00472c; }
.irc-bg22 { background-color: #004747; }
.irc-bg23 { background-color: #002747; }
.irc-bg24 { background-color: #000047; }
.irc-bg25 { background-color: #2e0047; }
.irc-bg26 { background-color: #470047; }
.irc-bg27 { background-color: #47002a; }
.irc-bg28 { background-color: #740000; }
.irc-bg29 { background-color: #743a00; }
.irc-bg30 { background-color: #747400; }
.irc-bg31 { background-color: #517400; }
.irc-bg32 { background-color: #007400; }
.irc-bg33 { background-color: #007449; }
.irc-bg34 { background-color: #007474; }
.irc-bg35 { background-color: #004074; }
.irc-bg36 { background-color: #000074; }
.irc-bg37 { background-color: #4b0074; }
.irc-bg38 { background-color: #740074; }
.irc-bg39 { background-color: #740045; }
.irc-bg40 { background-color: #b50000; }
.irc-bg41 { background-color: #b56300; }
.irc-bg42 { background-color: #b5b500; }
.irc-bg43 { background-color: #7db500; }
.irc-bg44 { background-color: #00b500; }
.irc-bg45 { background-color: #00b571; }
.irc-bg46 { background-color: #00b5b5; }
.irc-bg47 { background-color: #0063b5; }
.irc-bg48 { background-color: #0000b5; }
.irc-bg49 { background-color: #7500b5; }
.irc-bg50 { background-color: #b500b5; }
.irc-bg51 { background-color: #b5006b; }
.irc-bg52 { background-color: #f00; }
.irc-bg53 { background-color: #ff8c00; }
.irc-bg54 { background-color: #ff0; }
.irc-bg55 { background-color: #b2ff00; }
.irc-bg56 { background-color: #0f0; }
.irc-bg57 { background-color: #00ffa0; }
.irc-bg58 { background-color: #0ff; }
.irc-bg59 { background-color: #008cff; }
.irc-bg60 { background-color: #00f; }
.irc-bg61 { background-color: #a500ff; }
.irc-bg62 { background-color: #f0f; }
.irc-bg63 { background-color: #ff0098; }
.irc-bg64 { background-color: #ff5959; }
.irc-bg65 { background-color: #ffb459; }
.irc-bg66 { background-color: #ffff71; }
.irc-bg67 { background-color: #cfff60; }
.irc-bg68 { background-color: #6fff6f; }
.irc-bg69 { background-color: #65ffc9; }
.irc-bg70 { background-color: #6dffff; }
.irc-bg71 { background-color: #59b4ff; }
.irc-bg72 { background-color: #5959ff; }
.irc-bg73 { background-color: #c459ff; }
.irc-bg74 { background-color: #f6f; }
.irc-bg75 { background-color: #ff59bc; }
.irc-bg76 { background-color: #ff9c9c; }
.irc-bg77 { background-color: #ffd39c; }
.irc-bg78 { background-color: #ffff9c; }
.irc-bg79 { background-color: #e2ff9c; }
.irc-bg80 { background-color: #9cff9c; }
.irc-bg81 { background-color: #9cffdb; }
.irc-bg82 { background-color: #9cffff; }
.irc-bg83 { background-color: #9cd3ff; }
.irc-bg84 { background-color: #9c9cff; }
.irc-bg85 { background-color: #dc9cff; }
.irc-bg86 { background-color: #ff9cff; }
.irc-bg87 { background-color: #ff94d3; }
.irc-bg88 { background-color: #000; }
.irc-bg89 { background-color: #131313; }
.irc-bg90 { background-color: #282828; }
.irc-bg91 { background-color: #363636; }
.irc-bg92 { background-color: #4d4d4d; }
.irc-bg93 { background-color: #656565; }
.irc-bg94 { background-color: #818181; }
.irc-bg95 { background-color: #9f9f9f; }
.irc-bg96 { background-color: #bcbcbc; }
.irc-bg97 { background-color: #e2e2e2; }
.irc-bg98 { background-color: #fff; }

.irc-bold {
	font-weight: bold;
}

.irc-underline {
	text-decoration: underline;
}

.irc-strikethrough {
	text-decoration: line-through;
}

.irc-underline.irc-strikethrough {
	text-decoration: underline line-through;
}

.irc-italic {
	font-style: italic;
}

.tooltipped::after {
	font-size: 12px;
}

@media (min-width: 480px) {
	/* Fade out for long usernames */
	#chat .from {
		padding-left: 10px;
		-webkit-mask-image: linear-gradient(to left, transparent, black 10px);
		mask-image: linear-gradient(to left, transparent, black 10px);
	}
}

@media (max-width: 768px) {
	/**
	  * TODO Replace this with `@media (hover: hover)` when Firefox supports it
	  * See:
	  * - http://stackoverflow.com/a/28058919/1935861
	  * - http://caniuse.com/#feat=css-media-interaction
	  * - https://www.w3.org/TR/mediaqueries-4/
	  * - https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover
	  */
	.tooltipped-no-touch:hover::before,
	.tooltipped-no-touch:hover::after {
		visibility: hidden;
		opacity: 0;
	}

	#sidebar button,
	#sidebar .chan,
	#sidebar .empty,
	#windows label,
	#windows .header .topic,
	#settings .error,
	#help .help-item,
	#loading,
	#context-menu,
	#form #input,
	.textcomplete-menu,
	.messages .msg {
		font-size: 15px;
	}

	#sidebar {
		display: flex;
		background: var(--body-bg-color);
		height: 100%;
		position: absolute;
		left: -220px;
		z-index: 2;
		transition: transform 160ms;
		transform: translateZ(0);
	}

	#sidebar-overlay {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.5);
		opacity: 0;
		visibility: hidden;
		transition: opacity 160ms, visibility 160ms;
		z-index: 1;
	}

	#viewport.menu-open #sidebar-overlay {
		opacity: 1;
	}

	#viewport.menu-open #sidebar {
		transform: translate3d(220px, 0, 0);
	}

	#viewport.menu-dragging #sidebar-overlay,
	#viewport.menu-dragging #sidebar {
		transition: none;
	}

	#viewport.menu-open #sidebar,
	#viewport.menu-dragging #sidebar {
		box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
	}

	#viewport.menu-open #sidebar-overlay,
	#viewport.menu-dragging #sidebar-overlay {
		visibility: visible;
	}

	#sidebar .empty::before {
		margin-top: 0;
	}

	#viewport .lt,
	#viewport .channel .rt {
		display: flex;
	}

	/* On mobile display, channel list button stays at the top */
	#viewport .lt {
		position: relative;
	}

	#chat .userlist {
		background-color: var(--window-bg-color);
		height: 100%;
		position: absolute;
		right: 0;
		transform: translateX(180px);
		transition: transform 0.2s;
	}

	#viewport.userlist-open #chat .userlist {
		transform: translateX(0);
	}

	#chat .header .title {
		padding-left: 6px;
	}

	#chat .toggle-content .thumb {
		max-height: 58px;
		max-width: 104px;
	}
}

@media (max-width: 479px) {
	.container {
		margin: 0;
	}

	#sign-in .btn {
		width: 100%;
	}

	#connect .tls {
		margin: 20px 0;
	}

	#windows .input {
		margin-bottom: 2px;
	}

	#chat .messages {
		display: block;
		padding: 5px 0;
	}

	#chat .msg {
		display: block;
		padding: 2px 10px;
	}

	#chat .condensed .msg {
		padding: 2px 0;
	}

	#chat .time,
	#chat .from,
	#chat .content {
		border: 0;
		display: inline;
		padding: 0;
	}

	#chat .channel .msg.highlight {
		padding-left: 5px;
	}

	#chat .channel .msg.highlight .time {
		padding-left: 0;
	}

	#chat .condensed-summary .time,
	#chat .condensed-summary .from {
		display: none;
	}

	#help .help-item .subject {
		display: inline-block;
		padding-bottom: 4px;
	}

	#help .help-item .description {
		display: block;
	}
}

::-webkit-scrollbar {
	width: 8px;
	background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar:hover {
	background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
	background: rgba(0, 0, 0, 0.5);
	border-radius: 100px;
}

::-webkit-scrollbar-thumb:vertical:active {
	background: rgba(0, 0, 0, 0.6);
}

/* Image viewer and drag-and-drop overlay */

#upload-overlay,
#image-viewer,
#image-viewer .close-btn {
	/* Vertically and horizontally center stuff */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#upload-overlay,
#image-viewer {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: black;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.2s, visibility 0.2s;
	z-index: 999;
}

#image-viewer.opened {
	visibility: visible;
	opacity: 1;
}

#upload-overlay.is-dragover {
	visibility: visible;
	opacity: 0.3;
}

#image-viewer .close-btn,
#image-viewer .previous-image-btn,
#image-viewer .next-image-btn {
	position: fixed;
	top: 0;
	width: 2em;
	font-size: 36px;
	color: white;
	opacity: 0.6;
	transition: 0.2s opacity;
}

#image-viewer .close-btn {
	right: 0;
	height: 2em;
	z-index: 1002;
}

#image-viewer .close-btn::before {
	content: "\D7";
}

#image-viewer .previous-image-btn,
#image-viewer .next-image-btn {
	bottom: 0;
	z-index: 1001;
}

#image-viewer .previous-image-btn {
	left: 0;
}

#image-viewer .next-image-btn {
	right: 0;
}

#image-viewer .close-btn:hover,
#image-viewer .previous-image-btn:hover,
#image-viewer .next-image-btn:hover {
	opacity: 1;
}

#image-viewer .image-link {
	margin: 10px;
}

#image-viewer .image-link:hover {
	opacity: 1;
}

#image-viewer .image-link img {
	max-width: 100%;

	/* Top/Bottom margins + button height + image/button margin */
	max-height: calc(100vh - 2 * 10px - 37px - 10px);

	/* Checkered background for transparent images */
	background-position: 0 0, 10px 10px;
	background-size: 20px 20px;
	background-image:
		linear-gradient(45deg, #eee 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 75%, #eee 75%, #eee 100%),
		linear-gradient(45deg, #eee 25%, #fff 25%, #fff 75%, #eee 75%, #eee 100%);
}

#image-viewer .open-btn {
	margin: 0 auto 10px;
}

/* Correctly handle multiple successive whitespace characters.
   For example: user has quit ( ===> L   O   L <=== )  */

#windows .header .topic,
#chat .message .text,
#chat .motd .text,
#chat .notice .text,
#chat .ctcp-message,
#chat .part-reason,
#chat .quit-reason,
#chat .new-topic,
#chat .action .text,
#chat table.channel-list .topic {
	white-space: pre-wrap;
}
